<script lang="ts" setup>
import type { NavItem } from '~~/types/storyblok/navigation'

const props = defineProps<{ blok: NavItem }>()

const storyUrl = (blok: NavItem) => {
  let url = blok.link.url || blok.link.cached_url || '/'
  if (url.endsWith('/')) url = url.slice(0, -1)
  return '/' + url
}

const url = computed(() => {
  switch (props.blok.link.linktype) {
    case 'story':
      return storyUrl(props.blok)
    case 'url':
    case 'asset':
      return props.blok.link.url
    case 'email':
      return 'mailto:' + props.blok.link.email
    default:
      return '#'
  }
})
</script>

<template lang="pug">
NuxtLink(
  v-if="blok"
  :to="url"
  class="nav-link block cursor-pointer"
  v-editable="blok"
  exact
)
  | {{ blok.label }}
</template>
